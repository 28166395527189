import { Context, ProviderProps, StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './app/app';

import DataStore from 'src/store/DataStore.jsx';
import AppStore from 'src/store/AppStore.jsx';
import AuthStore from 'src/store/AuthStore.jsx';
import ContactUsStore from 'src/store/ContactUsStore.jsx';
import ProjectsFiltersStore from 'src/store/ProjectsFiltersStore.jsx';
import ProjectsMapStore from 'src/store/ProjectsMapStore.jsx';
import CacheStore from 'src/store/CacheStore.jsx';
import ProjectsStore from 'src/store/ProjectsStore.jsx';

// Registering the Font awesome icons
import registerSentry from 'src/helpers/registerSentry';
import registerIcons from './app/resources/icons/registerFaIcons';
import { setLanguage } from 'src/localization/localizer';
registerIcons();
setLanguage('nl');

registerSentry();

const buildContextProvidersTree = (providers: any[]) => {
  const initialComponent = ({ children }: any) => <>{children}</>;
  return providers.reduce((AccumulatedProviders, Provider) => {
    return ({ children }: any) => {
      return (
        <AccumulatedProviders>
          <Provider>{children}</Provider>
        </AccumulatedProviders>
      );
    };
  }, initialComponent);
};

const ProvidersTree = buildContextProvidersTree([
  AppStore,
  DataStore,
  ContactUsStore,
  ProjectsFiltersStore,
  ProjectsMapStore,
  CacheStore,
  ProjectsStore,
  AuthStore
]);

// Trigger 3
ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <ProvidersTree>
        <App />
      </ProvidersTree>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);
